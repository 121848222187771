import React, { useEffect } from 'react'
import cx from 'classnames'
import { prepareParagraph } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ references }) => {
  if (!references || references.isHide) return null

  useEffect(() => {
    const autorefLinks = document.getElementsByClassName('autoref')
    Array.from(autorefLinks).forEach((item, index) => {
      item.href = item.href.replace('[AUTOREF_COUNT]', index + 1)
      item.innerText = index + 1
    })
  }, [])

  return (
    <div className={styles.references}>
      <div className={styles.wrap}>
        <div className={styles.table}>
          {references.items &&
            references.items.map((reference, index) => (
              <>
                <div className={styles.anchor} id={`reference_${index + 1}`}/>
                <div className={styles.row} key={reference._key}>
                  <div className={styles.c1}>{index + 1}</div>
                  <div className={styles.c2}>{prepareParagraph(reference.text)}</div>
                  <div className={styles.c3}>
                    <ul>
                      {reference.sources &&
                        reference.sources.map(source => (
                          <li key={source._key}>
                            <a href={source.url} target="_blank">
                              {source.title}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </>
            ))}
        </div>

        <p className={styles.info}>{prepareParagraph(references.info)}</p>
      </div>
    </div>
  )
}
