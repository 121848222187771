import React, { useState } from 'react'
import { prepareParagraph, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ startingPoint }) => {
  if (!startingPoint) return null

  const [chartModalShow, setChartModalShow] = useState(false)

  const openChartModalHandler = () => {
    setChartModalShow(true)
  }

  const closeChartModalHandler = () => {
    setChartModalShow(false)
  }

  return (
    <div className={styles.startingPoint}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{startingPoint.title}</h2>
        <p className={styles.text}>{startingPoint.description}</p>
        <div className={styles.items}>
          {startingPoint.items && startingPoint.items.map(item => (
            <div className={styles.item} key={item._key}>
              <p className={styles.sub}>{item.title}</p>
              <div className={styles.lines}>
                {item.lines && item.lines.map(line => (
                  <div className={styles.line} key={line._key}>
                    <p className={styles.lineText}>{prepareParagraph(line.text)}</p>
                    {line.infoIcon ? (
                      <img className={styles.lineImage} src={sanityImageUrl(line.infoIcon)} alt={line.infoIcon?.caption} />
                    ) : (
                      <p className={styles.lineInfo}>
                        {line.info}
                        {line.viewChartPopup && (
                          <>
                            <div className={styles.chartLink} onClick={openChartModalHandler}>{line.viewChartPopup.link}</div>
                            {chartModalShow && (
                              <div className={styles.chartModal}>
                                <div className={styles.chartModalBg} onClick={closeChartModalHandler} />
                                <div className={styles.chartModalWrap}>
                                  <div className={styles.chartModalBox}>
                                    <div className={styles.chartModalClose} onClick={closeChartModalHandler} />
                                    <p className={styles.chartModalTitle}>{line.viewChartPopup.title}</p>
                                    <div className={styles.chartModalContent}>
                                      {line.viewChartPopup.lines && line.viewChartPopup.lines.map(chartLine => (
                                        <div className={styles.chartModalRow} key={chartLine._key}>
                                          <p className={styles.chartModalText}>{chartLine.text}</p>
                                          <p className={styles.chartModalInfo}>{chartLine.info}</p>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )} 
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}