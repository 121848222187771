import React from 'react'
import { Helmet } from 'react-helmet'

export default ({ questions = [] }) => (
  <Helmet>
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org/",
          "@type": "FAQPage",
          "mainEntity": [${questions.map(({ title, text }) => {
            return JSON.stringify({
              '@type': 'Question',
              name: `${title}`,
              acceptedAnswer: {
                '@type': 'Answer',
                text: `${text}`,
              },
            })
          })}]
        }
      `}
    </script>
  </Helmet>
)
