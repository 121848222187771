import React from 'react'
import { sanityImageUrl } from '../../../utils/format'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ blocks }) => {
  if (!blocks || blocks.isHide) return null

  return (
    <div className={styles.blocks}>
      <div className={styles.block}>
        <img className={styles.blockBg} src={sanityImageUrl(blocks.block1Image)} alt={blocks.block1Image?.caption} />
        <div className={styles.content}>
          <div className={styles.top}>
            <p className={styles.block1Title}>{blocks.block1Title}</p>
          </div>
          <div className={styles.bottom}>
            <Link to={blocks.block1LinkUrl} className={styles.bottomLink}>{blocks.block1LinkText}</Link>
            <ul className={styles.block1List}>
              {blocks.block1List && blocks.block1List.map((item, index) => <li key={index}>{item}</li>)}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.block}>
        <img className={styles.blockBg} src={sanityImageUrl(blocks.block2Image)} alt={blocks.block2Image?.caption} />
        <div className={styles.content}>
          <div className={styles.top}>
            <img className={styles.stars} src={sanityImageUrl(blocks.block2Stars)} alt={blocks.block2Stars?.caption} />
            <p className={styles.block2Text}>{blocks.block2Text}</p>
          </div>
          <div className={styles.bottom}>
            <div className={styles.customer}>
              <p className={styles.block2Customer}>{blocks.block2Customer}</p>
              <p className={styles.block2Verified}>{blocks.block2Verified}</p>
            </div>
            <Link to={blocks.block2LinkUrl} className={styles.bottomLink}>{blocks.block2LinkText}</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
