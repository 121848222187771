import React from 'react'
import { urlWithSearchParamsHandler, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header || header.isHide) return null

  return (
    <div className={styles.header}>
      <img className={styles.headerBg} src={sanityImageUrl(header.image)} alt={header.image?.caption} />
      <div className={styles.wrap}>
        <h2 className={styles.title}>{header.title}</h2>
        <ul className={styles.benefits}>
          {header.benefits && header.benefits.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
        <a href={header.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>{header.buttonText}</a>
      </div>
    </div>
  )
}
