import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/format'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Top from '../components/AtHomeLabTestPage/Top'
import Header from '../components/AtHomeLabTestPage/Header'
import HowItWorks from '../components/AtHomeLabTestPage/HowItWorks'
import Low from '../components/AtHomeLabTestPage/Low'
import StartingPoint from '../components/AtHomeLabTestPage/StartingPoint'
import Blocks from '../components/AtHomeLabTestPage/Blocks'
import Stats from '../components/AtHomeLabTestPage/Stats'
import Benefits from '../components/AtHomeLabTestPage/Benefits'
import Faq from '../components/AtHomeLabTestPage/Faq'
import References from '../components/AtHomeLabTestPage/References'
import FoodDrug from '../components/AtHomeLabTestPage/FoodDrug'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Header header={data.header} />,
      priority: data.header?.priority || 99
    },
    {
      render: <HowItWorks howItWorks={data.howItWorks} />,
      priority: data.howItWorks?.priority || 99
    },
    {
      render: <Low low={data.low} />,
      priority: data.low?.priority || 99
    },
    {
      render: <StartingPoint startingPoint={data.startingPoint} />,
      priority: data.startingPoint?.priority || 99
    },
    {
      render: <Blocks blocks={data.blocks} />,
      priority: data.blocks?.priority || 99
    },
    {
      render: <Stats stats={data.stats} />,
      priority: data.stats?.priority || 99
    },
    {
      render: <Benefits benefits={data.benefits} />,
      priority: data.benefits?.priority || 99
    },
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99
    },
    {
      render: <References references={data.references} />,
      priority: data.references?.priority || 99
    },
    {
      render: <FoodDrug foodDrug={data.foodDrug} />,
      priority: data.foodDrug?.priority || 99
    }
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      {(data?.faq?.faqList?.items || data?.faq?.items) && (
        <FaqSchema questions={data?.faq?.faqList?.items || data?.faq?.items} />
      )}
      <Top top={data.top} />
      {components.map(component => component.render)}
    </Layout>
  )
}
