import React from 'react'
import { prepareParagraph } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ stats }) => {
  if (!stats || stats.isHide) return null

  return (
    <div className={styles.stats}>
      <div className={styles.wrap}>
        <div className={styles.column}>
          <h2 className={styles.title}>{stats.title}</h2>
          <p className={styles.description}>{stats.description}</p>
        </div>
        <div className={styles.column}>
          <div className={styles.lines}>
            {stats.lines && stats.lines.map(line => (
              <div className={styles.item} key={line._key}>
                <div className={styles.itemValue}>{line.value}</div>
                <div className={styles.itemDescription}>{prepareParagraph(line.description)}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
